import React, {useEffect} from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Section from "../../components/template-partials/global-components/section"
import ShareButtons from "../../components/template-partials/global-components/share-buttons"
import InteriorMenu from "../../components/template-partials/global-components/interior-menu"
import InteriorPageHeader from "../../components/template-partials/global-components/interior-page-header"
import ResourcesAndSupportSubmenu from "../../components/template-partials/resources-and-support/resources-and-support-submenu"
import headingBg from "../../images/1.0_backgroundheader.jpg"
import headingRound from "../../images/resources-and-support-nav-image.jpg"
import voca from "voca";
import glossaryTerms from "../../components/template-partials/resources-and-support/glossary-data"

const GlossaryPage = () => {

  useEffect(()=>{
    if(window.location.hash){
      let hash = window.location.hash.split('#')[1];
      let sh = document.getElementById(hash).offsetTop;
      setTimeout(function(){
        scrollPage(sh);
      },200);
    }
  },[])

  const scrollPage =(sh)=>{
    window.scroll({
      top: 0 + ( sh - 300),
      left: 0,
      behavior: 'smooth'
    });
  }

  const handleClick =(e)=>{
    let id = e.currentTarget.getAttribute('href');
    let sh = document.getElementById(id.split("#")[1]).offsetTop;
    window.history.pushState(null, null, `${id}`);
    scrollPage(sh);
    e.preventDefault();
  }

  return (
    <Layout>
      <SEO title="Neuroblastoma Glossary of Terms" description="A glossary of important medical terms that may be used throughout your child’s neuroblastoma journey." />

      <InteriorPageHeader id="top" backgroundImage={headingBg} roundImage={headingRound} alt="Glossary of Terms">
        <h1 className="section__heading section__heading--green h1">Glossary of Terms</h1>
      </InteriorPageHeader>

      {/* style={{backgroundColor: SassVars.viridian}} */}

      <Section className="interior-page lg_pb-5">
        <div className="row row--inner">
          <div className="columns small-12 large-4">
            <InteriorMenu title={`Resources`}>
              <ResourcesAndSupportSubmenu/>
            </InteriorMenu>
          </div>
          <div className="columns">
            <div className="row">
              <div className="columns">
                <ShareButtons/>
              </div>
            </div>
            <div className="row">
              <div className="columns">
                <h2 className="tc-viridian h3 jost-med">Click on a letter to jump to a section</h2>
                <ul className="ul--glossary">
                  {glossaryTerms.terms.map((term, index) => (
                    <li key={index}><a onClick={(e)=>handleClick(e)} href={`#` + term.title}>{term.title}</a></li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        {glossaryTerms.terms.map((term, index) => (
          <div key={index} id={term.title}  className="row row--inner lg_mt-4 mt-4">
            <div className="columns">
              <div className="row align-bottom lg_mb-4 mb-3">
                <div className="columns shrink">
                  <h3 className="tc-barney h2 jost-med uppercase no-pad">{term.title}</h3>
                </div>
                <div className="columns">
                  <p className="no-pad"><a href="#top">&uarr; back to top</a></p>
                </div>
              </div>
              {term.terms.map((term, index) => (
              <div id={voca.kebabCase(term.title)} key={index} className={(index > 1)? `row lg_mt-2` : `row`}>
                <div className="columns">
                  <h4 className="tc-viridian h4 jost-med"><em>{term.title}</em></h4>
                  <p>{term.definition}</p>
                </div>
              </div>
              ))}
            </div>
          </div>
        ))}
      </Section>

    </Layout>
  )
}

export default GlossaryPage
